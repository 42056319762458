import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { clinicalPathway } from "../../APIs/earProblem";
import { pathwayQuestions } from "../../redux/slices/pathwayQuestionsSlice";
import { problemType } from "../../redux/slices/problemTypeSlice";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import loader from "../../Assets/Images/loader.gif";
import { useTranslation } from "react-i18next";

const PrePathwayComponent = () => {
  const [answer, setAnswer] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);

  const { t } = useTranslation();
  const disease = useSelector((state) => state.problemType.problem);
  const clinicalProblemsReason = localStorage.getItem("reason");
  const { version } = useSelector((state) => state.basicDetail);
  const usingLanguage = localStorage.getItem("i18nextLng");

  const getPathwaySwitchingQuestion = () => {
    const { question } = disease;
    if (version === "firstPerson") {
      return question.multiLanguageDescription?.length
        ? question.multiLanguageDescription.find(
            (m) => m.language === usingLanguage
          )?.content || question.multiLanguageDescription[0]?.content
        : question.description;
    } else if (version === "proxy") {
      return question.multiLanguageDescriptionProxy?.length
        ? question.multiLanguageDescriptionProxy.find(
            (m) => m.language === usingLanguage
          )?.content || question.multiLanguageDescriptionProxy[0]?.content
        : question.multiLanguageDescription?.length
        ? question.multiLanguageDescription.find(
            (m) => m.language === usingLanguage
          )?.content || question.multiLanguageDescription[0]?.content
        : question.description;
    } else if (version === "paediatric") {
      return question.multiLanguageDescriptionPaediatric?.length
        ? question.multiLanguageDescriptionPaediatric.find(
            (m) => m.language === usingLanguage
          )?.content || question.multiLanguageDescriptionPaediatric[0]?.content
        : question.multiLanguageDescription?.length
        ? question.multiLanguageDescription.find(
            (m) => m.language === usingLanguage
          )?.content || question.multiLanguageDescription[0]?.content
        : question.description;
    } else {
      return "Pathway switching question not found.";
    }
  };
  const handleNext = async () => {
    if (!answer) {
      return toast.error(`${t("please_selec_answer")}`);
    } else {
      const { options } = disease.question;
      const filteredDisease = options[0]?.version
        ? options?.find(
            (q) =>
              t(q.optionDescription.toLowerCase()) === answer &&
              q.version === version
          )
        : options?.find(
            (q) => t(t(q.optionDescription).toLowerCase()) === answer
          );

      if (clinicalProblemsReason === "General" && answer === t("yes"))
        return navigate("/post-ecds", {
          state: { pathway: filteredDisease?.pathway },
        });
      setShowLoader(true);
      const data = {
        diseaseID: filteredDisease?.pathway,
        version: version,
      };
      const pathway = await clinicalPathway(data);
      if (pathway) {
        dispatch(pathwayQuestions(pathway));
        if (filteredDisease.pathwayName) {
          dispatch(problemType({ problem: filteredDisease.pathwayName }));
        }
        const showBadPainImages =
          filteredDisease?.pathway === "63ecf848259a49f1974b3218" ? true : false;
        navigate("/pathway", { state: { showBadPainImages } });
      }
    }
  };
  return (
    <div className="ecds-container">
      <div className="content-div">
        <div className="arrow-left">
          <img
            className="arrow-left-img"
            src={require("../../Assets/Images/arrow-left.png")}
            alt="arrow"
            onClick={() => navigate("/triage")}
          />
        </div>
        {showLoader && (
          <img className="loader_div" src={loader} alt="loading..." />
        )}
        <div className="question">{getPathwaySwitchingQuestion()}</div>

        <div
          onClick={() => {
            setAnswer(`${t("yes")}`);
          }}
          className={
            answer === `${t("yes")}`
              ? "active-option answer-div"
              : "inactive-option answer-div"
          }
        >
          {t("yes")}
        </div>
        <div
          onClick={() => {
            setAnswer(`${t("no")}`);
          }}
          className={
            answer === `${t("no")}`
              ? "active-option answer-div"
              : "inactive-option answer-div"
          }
        >
          {t("no")}
        </div>
      </div>

      <button className="button-ecds-next br py-2" onClick={handleNext}>
        <span>{t("next")}</span>
      </button>
    </div>
  );
};

export default PrePathwayComponent;
