import React from "react";
import { useMediaQuery } from "react-responsive";
import PreWelcomeComponent from "../../Components/PreWelcome/PreWelcomeComponent";

export default function PreWelcome() {
  const isMobile = useMediaQuery({
    query: "(max-width: 820px)",
  });

  return (
    <div className="custom-container">
      {isMobile ? (
        <div className="mobile-box-pe">
          <PreWelcomeComponent />
        </div>
      ) : (
        <div className="backdrop">
          <div className="pc-box-pe position-relative">
            <PreWelcomeComponent />
          </div>
        </div>
      )}
    </div>
  );
}
