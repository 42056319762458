import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { deviceApproval } from "../../APIs/hospital";
import { toast } from "react-toastify";

export default function PostTriageComponent() {
  const navigate = useNavigate();

  useEffect(() => {
    handleNext();
  }, []);

  const handleNext = async () => {
    const deviceId = localStorage.getItem("deviceId");
    if (deviceId) {
      const approval = await deviceApproval({ deviceId });
      if (approval.data.isActive && approval.data.isApproved) {
        navigate("/booking");
      } else {
        return toast.error("Device not approved");
      }
    }
  };

  return (
    <div className="ecds-container takeSeat-Container">
      <div className="takeSeat takeApproval">
        <img
          src={require("../../Assets/Images/approvalLogo.png")}
          className="logoCenter"
          alt="logo"
        />
        <span className="approval">
          ED approval <br /> required for device access
        </span>
        <p className="detailApproval">
          In order to proceed please go to the ED office to get your new device
          approved.
        </p>
      </div>
      <button className="button-post-triage-next br py-2" onClick={handleNext}>
        <span>Retry</span>
      </button>
    </div>
  );
}
