import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function CommitmentComponent() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNext = async () => {
    navigate("/language");
  };

  return (
    <div className="ecds-container">
      <div className="content-div-5">
        <div className="text-center">
          <img
            src={require("../../Assets/Images/logo.png")}
            className="logo-smaller"
            alt="logo"
          />
        </div>
        <div className="question text-center">
          {t("our_data_commitment_to_you")}
        </div>
        <p className="commitment_detail h-custom">
          <div>{t("data_privacy")}</div>
          <br />
          <div> {t("point_two_data_privacy")}</div>
          <br />
        </p>
      </div>
      <button className="button-ecds-next br py-2" onClick={handleNext}>
        <span>{t("next")}</span>
      </button>
    </div>
  );
}
