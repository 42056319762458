import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { login } from "../../redux/slices/userSlice";
import { signup } from "../../APIs/signup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import loader from "../../Assets/Images/loader.gif";
import { useTranslation } from 'react-i18next';

const AccompanyingComponent = () => {
  const [accompanying, setAccompanying] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const signupData = useSelector(state => state.signup.value)
  const { version } = useSelector(state => state.basicDetail)
  console.log(version,'version')


  const handleNext = async () => {
    if (!accompanying) {
      return toast.error(`${t('please_selec_answer')}`);
    }
    if (accompanying === t('yes')) {
      navigate('/accompany')
    }
    else {
      setShowLoader(true);
      let response = await signup(signupData);
      if (response.status && response?.status !== false) {
        setShowLoader(false);
        dispatch(login(response?.data));
        localStorage.setItem("opto_token", response?.data.token);
        toast.success(
          <div>
            <span className="bold">{t('success')}</span> <br /> {t('check_in_success')}
          </div>
        );
				navigate("/pre-ecds");
      } else {
        if (response?.message) toast.error(response?.message);
        else toast.error("Someting went wrong. Please try again!");
        setShowLoader(false);
      }
    }
  };

  const handlePrevious = () => {
    navigate("/emergency-contact");
  };

  return (
    <div className="ecds-container">
      <div className="content-div">
        <img
          src={require("../../Assets/Images/logo.png")}
          className="logo-smaller"
          alt="logo"
        />
        <div className="arrow-left">
          <img
            className="arrow-left-img"
            src={require("../../Assets/Images/arrow-left.png")}
            alt="arrow"
            onClick={handlePrevious}
          />
        </div>
        {showLoader && (
          <img className="loader_div" src={loader} alt="loading..." />
        )}
        <div className="question">{version=='firstPerson' ? t('is_anyone_accompanying_you'): t('is_anyone_accompanying')}</div>
        <div>
          <div
            onClick={() => setAccompanying(`${t('yes')}`)}
            className={
              accompanying === `${t('yes')}`
                ? "active-option answer-div"
                : "inactive-option answer-div"
            }
          >
            {t('yes')}
          </div>
          <div
            onClick={() => setAccompanying(`${t('no')}`)}
            className={
              accompanying === `${t('no')}`
                ? "active-option answer-div"
                : "inactive-option answer-div"
            }
          >
            {t('no')}
          </div>
        </div>
      </div>
      <button className="button-ecds-next br py-2" onClick={handleNext}>
        <span>{t('next')}</span>
      </button>
    </div>
  );
};

export default AccompanyingComponent;
