import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { monthList, convertMonthsToArabic, convertDateToArabic } from "../Config/constants"
import moment from "moment";
import { useTranslation } from 'react-i18next';


export default function DatePickerUi({ setDob, setShowPicker }) {

  const [yearsList, setYearsList] = useState([])
  const [datesList, setDatesList] = useState([])
  const [selectedMonth, setSelectedMonth] = useState('')
  const [selectedYear, setSelectedYear] = useState('')
  const [selectedDay, setSelectedDay] = useState('')
  const { t } = useTranslation();
  const usingLanguage = localStorage.getItem("i18nextLng")

  useEffect(() => {
    const day = selectedDay;
    const month = moment().month(selectedMonth).format("MM");
    const year = selectedYear
    const newDate = `${month}/${day}/${year}`
    if (selectedDay && selectedMonth && selectedYear) {
      setDob(newDate)
    }
  }, [selectedMonth, selectedYear, selectedDay])

  const getYears = (back) => {
    const year = new Date().getFullYear();
    return Array.from({ length: back }, (v, i) => year - back + i + 1);
  }
  function convertYearsToArabic(years) {
    const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    return years.map((year) => {
      const arabicDigits = year.yearName.toString().split('').map(digit => arabicNumerals[digit]);
      return { yearName: arabicDigits.join(''), value: year.value };
    });
  }

  useEffect(() => {
    const years = getYears(150).reverse();
    const updatedYears = years.map((y) => { return { yearName: y, value: y } })
    const yearsList = usingLanguage === 'en' ? updatedYears : convertYearsToArabic(updatedYears);
    setYearsList(yearsList);
  }, []);

  const handleMonthList = () => {
    const updatedMonthList = monthList.map((m) => { return { monthName: m, value: m } })
    const data = usingLanguage === 'en' ? updatedMonthList : convertMonthsToArabic(monthList);
    return data;
  };



  useEffect(() => {
    const year = new Date().getFullYear();
    const month = selectedMonth;
    const daysInMonth = moment(`${year}-${month + 1}`, 'YYYY-MM').daysInMonth();
    const dates = [];
    for (let date = 1; date < daysInMonth; date++) {
      dates.push(moment(`${date}`, 'DD').toDate());
    }
    const data = dates.map((d) => (new Date(d).getDate()))
    if (month === 'Jan' || month === 'Mar' || month === 'May' || month === 'Jul' || month === 'Aug' || month === 'Oct' || month === 'Dec') {
      data.push('31')
    }
    if (month === 'Feb') {
      data.splice(-2)
    }
    const updatedDates = data.map((d) => { return { day: d, value: d } })
    setDatesList(usingLanguage === 'en' ? updatedDates : convertDateToArabic(data))
  }, [selectedMonth])

  return (
    <>
      <div>
        <div className="input-container">
          <div className="dateWrapper">
            <select onChange={(e) => {
              setSelectedYear(e.target.value)
            }} className="colored-border trans-background mb-2 font-13 width-250 pd-10 selectField form-select">
              <option>{t('year')}</option>
              {yearsList.map((y, i) => (
                <option key={i} value={y.value}>{y?.yearName}</option>
              ))}
            </select>
          </div>
          <div className="dateWrapper">
            <select onChange={(e) => setSelectedMonth(e.target.value)} className="colored-border trans-background mb-2 font-13 width-250 pd-10 selectField form-select">
              <option>{t('month')}</option>
              {handleMonthList()?.map((m, i) => (<option key={i} value={m.value}>{m.monthName}</option>))}
            </select>
          </div>
          <div className="dateWrapper">
            <select onChange={(e) => setSelectedDay(e.target.value)} className="colored-border trans-background mb-2 font-13 width-250 pd-10 selectField form-select">
              <option>{t('date')}</option>
              {datesList.map((d, i) => (
                <option key={i} value={d.value}>{d.day}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>

  );
}