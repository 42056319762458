import React from "react";
import Dialog from "@mui/material/Dialog";
import { useSelector } from "react-redux";
export default function Popup({ show, setShow, message }) {
  const isLightMode = useSelector(state => state.isLightMode?.value)
  return (
    <Dialog open={show} className={`popup-parent ${isLightMode && "dark-color"}`}>
      <div className="child-popup">
        <div className="popup-content">{message}</div>
        <button
          className="button-popup py-1"
          onClick={() => {
            setShow(false);
          }}
        >
          OK
        </button>
      </div>
    </Dialog>
  );
}
