export const getSelectedOptionName = (o, lang) => {
  return o?.multiLanguageOption?.length
    ? o?.multiLanguageOption.find((d) => d.language === lang)?.content ||
        o?.multiLanguageOption[0]?.content
    : o.option;
};

export const getCurrentQuesContent = (q, lang) => {
  return q?.multiLanguageQuestion?.length
    ? q.multiLanguageQuestion?.find((d) => d.language === lang)?.content ||
        q.multiLanguageQuestion[0]?.content
    : q?.question;
};
