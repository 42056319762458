import React from "react";
import { useMediaQuery } from "react-responsive";
import ChildAgeComponent from "../../Components/ChildAge/ChildAgeComponent";
import ProgressSlider from "../../Components/Shared/ProgressSlider";

export default function ChildAge() {
  const isMobile = useMediaQuery({
    query: "(max-width: 820px)",
  });

  return (
    <div className="custom-container">
      {isMobile ? (
        <div className="mobile-box-pe post-box-red">
          <ChildAgeComponent />
        </div>
      ) : (
        <div className="backdrop">
          <div className="pc-box-pe post-box-red position-relative">
            <ChildAgeComponent />
          </div>
        </div>
      )}
    </div>
  );
}
