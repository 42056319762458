import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ecds,
  ecdsQuestions,
  publicHealthData,
  setPublicHealthQuestions,
} from "../../redux/slices/ecdsSlice";
import { loading } from "../../redux/slices/loadingSlice";
import { useTranslation } from "react-i18next";
import {
  getEcdsData,
  getEcdsQuestionForUkMobile,
} from "../../APIs/ecds";
import SPOKEN_LANGUAGES from "../../Config/spoken_languages.json";

export default function PreEcdsComponent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { version } = useSelector((state) => state.basicDetail);
  const questions = useSelector((state) => state.ecds.questions);
  const publicHealthQuestions = useSelector(
    (state) => state.ecds.publicHealthQuestions
  );
  const usingLanguage = localStorage.getItem("i18nextLng");

  const handleNext = () => {
    if (publicHealthQuestions && publicHealthQuestions?.length)
      navigate("/public-health-questions");
    else if (questions && questions?.length) navigate("/pre-ecds-questions");
  };

  const getEcdsQuestionsList = async () => {
    const data = await getEcdsQuestionForUkMobile(version, false);
    if (!data?.length) return;
    dispatch(
      ecdsQuestions(
        data.map((q) =>
          [11, 13].includes(q.questionNumber)
            ? {
                ...q,
                options: [
                  ...q.options,
                  ...SPOKEN_LANGUAGES.map((l) => ({
                    ...q.options[1],
                    option: l["en"],
                    multiLanguageOption: null,
                    _id: l._id,
                  })),
                ],
              }
            : q
        )
      )
    );
  };

  const getPublicHealthQuestionsList = async () => {
    const { publicHealthQuestions } = await getEcdsData(version, usingLanguage);
    if (publicHealthQuestions.length)
      dispatch(setPublicHealthQuestions(publicHealthQuestions));
  };

  useEffect(() => {
    dispatch(loading({ percent: 0 }));
    dispatch(ecds(null));
    dispatch(publicHealthData(null));
    getEcdsQuestionsList();
    getPublicHealthQuestionsList();
  }, []);

  return (
    <div className="px-4 d-flex flex-column align-items-center">
      <img
        src={require("../../Assets/Images/smile.png")}
        className="smilie"
        alt="logo"
      />

      <div className="info2 my-5 px-4">
        {t(
          version === "firstPerson"
            ? "next_few_questions"
            : "next_few_questions1"
        )}
      </div>

      <div className=" d-flex flex-column align-items-center">
        <button className="button-started py-2 br" onClick={handleNext}>
          <span>{t("next")}</span>
        </button>
      </div>
    </div>
  );
}
