import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: null,
};

export const slice = createSlice({
    name: "themeSlice",
    initialState,
    reducers: {
        isLightMode: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { isLightMode } = slice.actions;

export default slice.reducer;
