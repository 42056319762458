import React from "react";
import { useMediaQuery } from "react-responsive";
import ClinicalPathwayComponent from "../../Components/ClinicalPathway/ClinicalPathwayComponent";
import ProgressSlider from "../../Components/Shared/ProgressSlider";
import Timer from "../../Components/Shared/TimerComponent"

export default function AdminApproval() {
  const isMobile = useMediaQuery({
    query: "(max-width: 820px)",
  });

  return (
    <div className="custom-container">
      {isMobile ? (
        <div className="mobile-box-pe ">
          <div className="slider">
            <ProgressSlider />
            {/* <Timer /> */}
          </div>
          <ClinicalPathwayComponent />
        </div>
      ) : (
        <div className="backdrop">
          <div className="pc-box-pe  position-relative">
            <div className="slider">
              <ProgressSlider />
              {/* <Timer /> */}
            </div>
            <ClinicalPathwayComponent />
          </div>
        </div>
      )}
    </div>
  );
}
