import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { ecds } from "../../redux/slices/ecdsSlice";
import { useNavigate } from "react-router-dom";
import { loading } from "../../redux/slices/loadingSlice";
import { useTranslation } from "react-i18next";
import Popup from "./Popup";
import {
  getCurrentQuesContent,
  getSelectedOptionName,
} from "../../HelperFunctions/helper";

const PreEcdsQuestionsComponent = () => {
  const { questions } = useSelector((state) => state.ecds);
  const [record, setRecord] = useState([]);
  const [popup, setPopup] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const usingLanguage = localStorage.getItem("i18nextLng");

  const handleNext = () => {
    if (record.length < 3)
      return toast.error("please select all the answers below");
    dispatch(ecds(record));
    navigate("/ecds");
  };

  const handlePrevious = () => {
    navigate("/");
  };

  useEffect(() => {
    dispatch(loading({ percent: 0 }));
  }, []);

  return (
    <div className="ecds-container">
      <Popup show={popup} setShow={setPopup} />
      <div className="arrow-left">
        <img
          className="arrow-left-img"
          src={require("../../Assets/Images/arrow-left.png")}
          alt="arrow"
          onClick={handlePrevious}
        />
      </div>
      <div className="d-flex check_question_wrap">
        <img
          src={require("../../Assets/Images/logo.png")}
          className="logo-smaller"
          alt="logo"
        />
        <h2>{t("please_select_those_that_apply")}</h2>
        {questions.slice(0, 3).map((q, idx) => {
          const q_content = getCurrentQuesContent(q, usingLanguage);
          return (
            <div className="check_question d-flex" key={idx}>
              <span>
                {q_content}
                {idx === 2 && (
                  <span
                    className="ms-1 info-icon-color cursor-pointer"
                    onClick={() => setPopup(true)}
                  >
                    ⓘ
                  </span>
                )}
              </span>
              {q.options.map((o, i) => {
                const o_content = getSelectedOptionName(o, usingLanguage);
                return (
                  <label htmlFor={o._id} className="check_box" key={i}>
                    <input
                      type="radio"
                      id={o._id}
                      onChange={() =>
                        setRecord((p) => {
                          const rec = [...p];
                          rec[idx] = {
                            nextQuestionNumber: o.nextQuestionNumber,
                            questionDetails: {
                              id: q._id,
                              content: q_content,
                              questionType: q.questionType,
                            },
                            answers: [
                              {
                                id: o._id,
                                code: o.code,
                                content: o_content,
                              },
                            ],
                          };
                          return rec;
                        })
                      }
                      checked={record[idx]?.answers[0]?.id === o._id}
                    />
                    <span>{o_content}</span>
                  </label>
                );
              })}
            </div>
          );
        })}
      </div>
      <button className="button-ecds-next br py-2" onClick={() => handleNext()}>
        <span>{t("next")}</span>
      </button>
    </div>
  );
};
export default PreEcdsQuestionsComponent;
