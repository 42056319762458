import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URI,
});

export const createDevice = async (data) => {
  try {
    let response = await api.post("devices/createDevice", data, {
      headers: {
        Authorization: localStorage.getItem("opto_token"),
      },
    });
    return response?.data;
  } catch (error) {
    console.log("ERROR", error);
    return error?.response?.data;
  }
};

export const deviceApproval = async (data) => {
  try {
    let response = await api.post("devices/checkDeviceStatus", data, {
      headers: {
        Authorization: localStorage.getItem("opto_token"),
      },
    });
    return response?.data;
  } catch (error) {
    console.log("ERROR", error);
    return error?.response?.data;
  }
};
