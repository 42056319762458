import React from "react";
import { useMediaQuery } from "react-responsive";
import PreWelcomeComponent from "../../Components/PreWelcome/PreWelcomeComponent";
import LanguageSelectorComponent from "../../Components/LanguageSelector/LanguageSelectorComponent"

export default function LanguageSelector() {
    const isMobile = useMediaQuery({
        query: "(max-width: 820px)",
    });

    return (
        <div className="custom-container">
            {isMobile ? (
                <div className="mobile-box-pe">
                    <LanguageSelectorComponent />
                </div>
            ) : (
                <div className="backdrop">
                    <div className="pc-box-pe position-relative">
                        <LanguageSelectorComponent />
                    </div>
                </div>
            )}
        </div>
    );
}
