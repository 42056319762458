import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import { bookingByDetails } from "../../redux/slices/basicDetialSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Popover } from "@mui/material";
import { COUNTRIES } from "../../Config/constants";
import { isValidPhoneNumber } from "react-phone-number-input";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTranslation } from 'react-i18next';

export default function ChildDetailComponent() {
  const [fName, setFName] = useState("");
  const [sName, setSName] = useState("");
  const [contact, setContact] = useState("");
  const [place, setPlace] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [popup, setPopup] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("+44");
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const numberRegex = new RegExp(`^[0-9]*$`);
  const navigate = useNavigate();
  const dispatc = useDispatch();
  const { t } = useTranslation();

  const handleNext = () => {
    if (!fName) {
      toast.error(`${t('enter_first_name')}`);
    } else if (!sName) {
      toast.error(`${t('enter_surname')}`);
    } else if (!contact) {
      toast.error(`${t('enter_phone')}`);
    } else if (!place) {
      toast.error(`${t('submit_answer')}`);
    } else {
      const num = `${selectedCountry}${contact}`;
      const validation = isValidPhoneNumber(num);
      if (validation === false) {
        return toast.error(`${t('invalid_mobile_number')}`);
      } else {
        const detailData = {
          firstname: fName,
          lastname: sName,
          phone: num,
          schoolOrNurery: place,
        };
        dispatc(bookingByDetails({ bookingByDetails: detailData }));
        navigate("/identifications-detail");
        // navigate("/basic-details");
      }
    }
  };

  const handlePrevious = () => {
    navigate("/child-relationship");
  };

  const checkNumRegex = (data) => {
    if (numberRegex.test(data) || data === "" || data.charAt(0) === "+") {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (search === "") {
      setFilteredData([]);
    } else {
      let data = [...COUNTRIES].filter((e) =>
        e.country.toLowerCase().includes(search)
      );
      setFilteredData(data);
      console.log({ data });
    }
  }, [search]);

  const showPopup = Boolean(anchorEl);
  const id = showPopup ? "simple-popover" : undefined;

  return (
    <div className="ecds-container">
      <div className="content-div overflow-initial">
        <div className="arrow-left">
          <img
            className="arrow-left-img"
            src={require("../../Assets/Images/arrow-left.png")}
            alt="arrow"
            onClick={handlePrevious}
          />
        </div>
        <img
          src={require("../../Assets/Images/logo.png")}
          className="logo-smaller"
          alt="logo"
        />
        <div className="question">{t('please_enter_your_detail')}</div>
        <div className="h-custom">
          <Form.Control
            className="text-fields mb-2 color-white"
            placeholder={t('first_name')}
            value={fName}
            onChange={(e) => {
              if (e.target.value.length < 31) {
                let newFName = e.target.value.replace(
                  /(^|[\s-])\S/g,
                  function (match) {
                    return match.toUpperCase();
                  }
                );
                setFName(newFName);
              }
            }}
          />
          <Form.Control
            className="text-fields mb-2 color-white"
            placeholder={t('last_name')}
            value={sName}
            onChange={(e) => {
              if (e.target.value.length < 31) {
                let newLName = e.target.value.replace(
                  /(^|[\s-])\S/g,
                  function (match) {
                    return match.toUpperCase();
                  }
                );
                setSName(newLName);
              }
            }}
          />
          <div className="d-flex">
            <div
              className="phone-input"
              onClick={(event) => {
                setPopup(true);
                setAnchorEl(event.currentTarget);
                setSearch("");
              }}
            >
              <div className="country-selection">{selectedCountry} </div>{" "}
              <ArrowDropDownIcon fontSize="small" color="ffffff99" />
            </div>

            <Popover
              open={popup}
              anchorEl={anchorEl}
              onClose={() => setPopup(false)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div className="p-3" style={{ height: "200px" }}>
                <Form.Control
                  style={{
                    background: "white",
                    color: "black",
                  }}
                  placeholder="Search"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                {(filteredData && filteredData.length > 0
                  ? filteredData
                  : COUNTRIES
                ).map((e) => (
                  <div
                    onClick={() => {
                      setPopup(false);
                      setSelectedCountry(`+${e.code}`);
                    }}
                    className="cursor-pointer my-1"
                    style={{ fontSize: "13px" }}
                  >{`${e.country} ( +${e.code} )`}</div>
                ))}
              </div>
            </Popover>
            <Form.Control
              className="text-field2 color-white"
              style={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderRight: "none !important",
              }}
              placeholder={t('phone_number')}
              value={contact}
              onChange={(e) => {
                if (checkNumRegex(e.target.value) === true) {
                  if (e.target.value.length < 15) {
                    setContact(e.target.value);
                  }
                }
              }}
            />
          </div>
          <div className="question">
            {t('what_school_or')}
          </div>
          <Form.Control
            as="textarea"
            className="trans-background no-resize color-white font-14 custom-field h-80p"
            placeholder={t('enter_answer')}
            value={place}
            onChange={(e) => {
              if (e.target.value.length < 50) {
                setPlace(e.target.value);
              }
            }}
          />
        </div>
      </div>
      <button className="button-ecds-next br py-2" onClick={handleNext}>
        <span>{t('next')}</span>
      </button>
    </div>
  );
}
