import React from "react";
import { useMediaQuery } from "react-responsive";
import PersonRelationshipComponent from "../../Components/Relationship/PersonRelationshipComponent";

export default function PersonRelationship() {
  const isMobile = useMediaQuery({
    query: "(max-width: 820px)",
  });

  return (
    <div className="custom-container">
      {isMobile ? (
        <div className="mobile-box-pe">
          <PersonRelationshipComponent />
        </div>
      ) : (
        <div className="backdrop">
          <div className="pc-box-pe position-relative">
            <PersonRelationshipComponent />
          </div>
        </div>
      )}
    </div>
  );
}
