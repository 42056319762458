import { useMediaQuery } from "react-responsive";
import PublicHealthQuestionsComponent from "../../Components/PublicHealthQuestions";
import ProgressSlider from "../../Components/Shared/ProgressSlider";

export default function PublicHealthQuestions() {
  const isMobile = useMediaQuery({
    query: "(max-width: 820px)",
  });

  return (
    <div className="custom-container">
      {isMobile ? (
        <div className="mobile-box-pe">
          <div className="slider">
            <ProgressSlider />
          </div>
          <PublicHealthQuestionsComponent />
        </div>
      ) : (
        <div className="backdrop">
          <div className="pc-box-pe position-relative">
            <div className="slider">
              <ProgressSlider />
            </div>
            <PublicHealthQuestionsComponent />
          </div>
        </div>
      )}
    </div>
  );
}
