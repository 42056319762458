import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

export default function PostTriageComponent() {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const isLightMode = useSelector(state => state.isLightMode?.value)

  const handleNext = () => {
    navigate("/");
  };

  return (
    <div className="ecds-container takeSeat-Container">
      <div className="takeSeat">
        <img
          src={isLightMode ? require("../../Assets/Images/posttriage.png") : require("../../Assets/Images/done.png")}
          className="logoCenter"
          alt="logo"
        />
        <p>
          {t('please_take_seat')}
        </p>
      </div>
      <button className="button-post-triage-next br py-2" onClick={handleNext}>
        <span>{t('ok')}</span>
      </button>
    </div>
  );
}
