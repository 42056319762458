import React from "react";
import { useMediaQuery } from "react-responsive";
import CommitmentComponent from "../../Components/Commitment/CommitmentComponent";

export default function Commitment() {
    const isMobile = useMediaQuery({
        query: "(max-width: 820px)",
    });

    return (
        <div className="custom-container">
            {isMobile ? (
                <div className="mobile-box-pe">
                    <CommitmentComponent />
                </div>
            ) : (
                <div className="backdrop">
                    <div className="pc-box-pe position-relative">
                        <CommitmentComponent />
                    </div>
                </div>
            )}
        </div>
    );
}
