import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loading } from "../../redux/slices/loadingSlice";
import { useTranslation } from 'react-i18next';

export default function PostDetailsComponent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  var User = useSelector((state) => state.userData.value);
  const { version, bookingByDetails } = useSelector(state => state.basicDetail)

  const handleNext = () => {
    dispatch(loading({ percent: 0 }));
    // if (User.user.nhsNumber === "1") {
    //   navigate("/booking");
    // } else {
    navigate("/pre-ecds");
    // }
  };

  return (
    <div className="px-4 d-flex flex-column align-items-center ">
      {User.user.nhsNumber === "2" ? (
        <>
          <div style={{ width: 130, minHeight: 105 }}>
            <img
              src={require("../../Assets/Images/crying.png")}
              className="smilie"
              alt="logo"
            />
          </div>
          <div className="heading mt-5">
            {t('could_not_fouind')}
          </div>

          <div className="info my-2">
            {t('hi')} {User.user.firstname} {User.user.lastname}, {t('unfortunately')}
          </div>

          <div className="mt-5 d-flex flex-column align-items-center">
            <button className="button-started py-2 br" onClick={handleNext}>
              <span>{t('ok')}</span>
            </button>
          </div>
        </>
      ) : (
        <>
          <div style={{ width: 130, minHeight: 105 }}>
            <img
              src={require("../../Assets/Images/smile.png")}
              className="smilie"
              alt="logo"
            />
          </div>
          <div className="heading mt-5">
            {t(version === "firstPerson" ? 'we_found_you' : "we_found_them")}
          </div>

          <div className="info my-2">
            {version === "firstPerson" ? <div> {t('hi')} {User.user.firstname} {User.user.lastname}, {t('we_have_found_nhs')}</div>
              :
              <div> {t('hi')} {User.user.firstname} {User.user.lastname}, {t('we_have_found')} {bookingByDetails?.firstname} {t('get_them_booked')} </div>}
          </div>

          <div className="mt-5 d-flex flex-column align-items-center">
            <button className="button-started py-2 br" onClick={handleNext}>
              <span>{t('lets_get_started')}</span>
            </button>
          </div>
        </>
      )}
    </div>
  );
}
