import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: null,
  questions: null,
  publicHealthData: null,
  publicHealthQuestions: null,
};

export const ecdsSlice = createSlice({
  name: "ecdsSlice",
  initialState,
  reducers: {
    ecds: (state, action) => {
      state.value = action.payload;
    },
    ecdsQuestions: (state, action) => {
      state.questions = action.payload;
    },
    publicHealthData: (state, action) => {
      state.publicHealthData = action.payload;
    },
    setPublicHealthQuestions: (state, action) => {
      state.publicHealthQuestions = action.payload;
    },
  },
});

export const { ecds, ecdsQuestions, publicHealthData, setPublicHealthQuestions } =
  ecdsSlice.actions;

export default ecdsSlice.reducer;
