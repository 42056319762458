import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { Switch } from "@mui/material";
import { isLightMode } from "../../redux/slices/themeSlice";
import { useDispatch, useSelector } from "react-redux";


const switchStyles = {
    color: '#ff0000',
    '&$checked': {
        color: '#ff0000',
    },
    '&$checked + $track': {
        backgroundColor: '#ff0000',
    },
};

export default function PreWelcomeComponent() {
    const [answer2, setAnswer2] = useState();
    const [selected, setSelected] = useState("en")
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const isLightModeCheck = useSelector(state => state.isLightMode?.value)
    const [isChecked, setIsChecked] = useState(isLightModeCheck);

    useEffect(() => {
        document.body.dir = languageMap[selected].dir;
        i18n.changeLanguage(selected);
    }, [selected])

    const languageMap = {
        en: { label: "English", dir: "ltr", active: true },
        ar: { label: "العربية", dir: "rtl", active: false }
    };

    const handleNext = async () => {
        if (!answer2) {
            return toast.error("Please select an option below");
        }
        i18n.changeLanguage(selected);
        document.body.dir = languageMap[selected].dir;
        const deviceId = localStorage.getItem("deviceId");
        if (deviceId) {
            navigate("/booking");
        } else {
            navigate('/hospital')
        }
    };

    const handleSwitchChange = (event) => {
        setIsChecked(event.target.checked);
        dispatch(isLightMode(event.target.checked))
    };

    return (
        <div className="ecds-container">
            <div className="d-flex switch-veriant">
                <p className="mx-1">{isLightModeCheck ? 'Light Mode' : 'Dark Mode'}</p>
                <Switch
                    checked={isChecked}
                    onChange={handleSwitchChange}
                    color="primary"
                    style={switchStyles}
                />
            </div>
            <div className="content-div">
                <img
                    src={require("../../Assets/Images/logo.png")}
                    className="logo-smaller ltr"
                    alt="logo"
                />

                <>
                    <div className="question text-center">{t("Select_Language")}</div>
                    {/* <div className="content-color font-13 my-2 text-center">{t("select_language_before_started")}</div> */}
                    <div>
                        {Object.keys(languageMap)?.map((item, i) => (
                            <div
                                key={i}
                                dir={item === 'en' ? "ltr" : "rtl"}
                                onClick={() => {
                                    setAnswer2(item === 'en' ? "English" : "العربية");
                                    setSelected(item)
                                }}
                                className={
                                    answer2 === (item === 'en' ? "English" : "العربية")
                                        ? "active-option answer-div"
                                        : "inactive-option answer-div"
                                }
                            >
                                {item === 'en' ? "English" : "العربية"}
                            </div>
                        ))}
                    </div>
                </>
            </div>

            <button className="button-ecds-next br py-2" onClick={handleNext}>
                <span>{t("Next")}</span>
            </button>
        </div>
    );
}
