import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Popover } from "@mui/material";
import { COUNTRIES } from "../../Config/constants";
import { isValidPhoneNumber } from "react-phone-number-input";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { login } from "../../redux/slices/userSlice";
import { signup } from "../../APIs/signup";
import { useDispatch, useSelector } from "react-redux";
import loader from "../../Assets/Images/loader.gif";

export default function AccompanyComponent() {
  const [fName, setFName] = useState("");
  const [relationship, setRelationship] = useState("")
  const [contact, setContact] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [popup, setPopup] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("+44");
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [otherRelation, setOtherRelation] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showCustomDrop, setShowCustomDrop] = useState(false);
  const signupData = useSelector(state => state.signup.value)
  const numberRegex = new RegExp(`^[0-9]*$`);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { version } = useSelector(state => state.basicDetail)
  const { state } = useLocation()


  const relations = [t('husband'), t('wife'), t('father'), t('mother'), t('grandparent'), t('partner'), t('son'), t('daughter'), t('carer'), t('friend'), t('neighbour')]
  const childRelations = [t('father'), t('mother'), t('brother'), t('sister'), t('grandparent'), t('carer'), t('neighbour')]



  const checkCustomDrop = () => {
    setShowCustomDrop(!showCustomDrop)
  }

  const handleNext = async () => {
    if (!fName) {
      toast.error(`${t('enter_name')}`);
    }
    else if (!contact) {
      toast.error(`${t('enter_phone')}`);
    }
    else if (!relationship) {
      toast.error(`${t('enter_relationship')}`);
    }
    else {
      const num = `${selectedCountry}${contact}`;
      const validation = isValidPhoneNumber(num);
      if (validation === false) {
        return toast.error(`${t('invalid_mobile_number')}`);
      } else {
        setShowLoader(true);
        let response = await signup(signupData);
        if (response.status && response?.status !== false) {
          setShowLoader(false);
          dispatch(login(response?.data));
          localStorage.setItem("opto_token", response?.data.token);
          toast.success(
            <div>
              <span className="bold">{t('success')}</span> <br /> {t('check_in_success')}
            </div>
          );
					navigate("/pre-ecds");
        } else {
          if (response?.message) toast.error(response?.message);
          else toast.error("Someting went wrong. Please try again!");
          setShowLoader(false);
        }
      }
    }
  };

  const checkNumRegex = (data) => {
    if (numberRegex.test(data) || data === "" || data.charAt(0) === "+") {
      return true;
    } else {
      return false;
    }
  };

  const handlePrevious = () => {
    navigate("/accompanying");
  };

  useEffect(() => {
    if (search === "") {
      setFilteredData([]);
    } else {
      let data = [...COUNTRIES].filter((e) =>
        e.country.toLowerCase().includes(search)
      );
      setFilteredData(data);
    }
  }, [search]);

  const showPopup = Boolean(anchorEl);
  const id = showPopup ? "simple-popover" : undefined;

  return (
    <div className="ecds-container">
      <div className="content-div overflow-initial">
        <div className="arrow-left">
          <img
            className="arrow-left-img"
            src={require("../../Assets/Images/arrow-left.png")}
            alt="arrow"
            onClick={handlePrevious}
          />
        </div>
        {showLoader && (
          <img className="loader_div" src={loader} alt="loading..." />
        )}
        <img
          src={require("../../Assets/Images/logo.png")}
          className="logo-smaller"
          alt="logo"
        />
        <div className="question d-flex justify-contact-center">{t('accompanying_person_detail')}</div>
        <div className="h-custom">
          <Form.Control
            className="text-fields mb-2 color-white"
            placeholder={t('name')}
            value={fName}
            onChange={(e) => {
              if (e.target.value.length < 31) {
                if (/^[a-zA-Z\u0600-\u06FF!@#\$%\^\&*\)\(+=._-\s]+$/.test(e.target.value || e.target.value === "")) {
                  let newFName = e.target.value.replace(/(^|[\s-])\S/g, function (match) {
                    return match.toUpperCase();
                  });
                  setFName(newFName);
                }
              }
            }}
          />
          <div className="d-flex mb-2">
            <div
              className="phone-input"
              onClick={(event) => {
                setPopup(true);
                setAnchorEl(event.currentTarget);
                setSearch("");
              }}
            >
              <div className="country-selection">{selectedCountry} </div>{" "}
              <ArrowDropDownIcon fontSize="small" color="ffffff99" />
            </div>

            <Popover
              open={popup}
              anchorEl={anchorEl}
              onClose={() => setPopup(false)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div className="p-3" style={{ height: "200px" }}>
                <Form.Control
                  style={{
                    background: "white",
                    color: "black",
                  }}
                  placeholder="Search"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                {(filteredData && filteredData.length > 0
                  ? filteredData
                  : COUNTRIES
                ).map((e) => (
                  <div
                    onClick={() => {
                      setPopup(false);
                      setSelectedCountry(`+${e.code}`);
                    }}
                    className="cursor-pointer my-1"
                    style={{ fontSize: "13px" }}
                  >{`${e.country} ( +${e.code} )`}</div>
                ))}
              </div>
            </Popover>
            <Form.Control
              className="text-field2 color-white"
              style={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderRight: "none !important",
              }}
              placeholder={t('phone_number')}
              value={contact}
              onChange={(e) => {
                if (checkNumRegex(e.target.value) === true) {
                  if (e.target.value.length < 15) {
                    setContact(e.target.value);
                  }
                }
              }}
            />
          </div>
          <div className="custom-dropdown-wrap">
            <Form.Control
              className="text-fields mb-2 color-white"
              placeholder={t('relationship_to_the_patient')}
              value={relationship}
              readOnly
              onClick={() => checkCustomDrop()}
            />
            <div className={`customdropdown ${showCustomDrop ? "active" : ""}`}>
              <ul>
                {version == 'paediatric' ? childRelations.map((r, i) => (
                  <li key={i} onClick={() => (setRelationship(r), checkCustomDrop())} className={`${relationship === r
                    && "active-option"
                    }`}>{r}</li>
                )) : relations.map((r, i) => (
                  <li key={i} onClick={() => (setRelationship(r), checkCustomDrop())} className={`${relationship === r
                    && "active-option"
                    }`}>{r}</li>
                ))}
                <li onClick={() => (setRelationship(`${t('other')}`), checkCustomDrop())}
                  className={`${relationship === `${t('other')}`
                    && "active-option"
                    }`}
                >
                  {t('other')}
                </li>
              </ul>
            </div>
          </div>

          {relationship === `${t('other')}` && (
            <>
              <div className="question">{version == 'firstPerson' ? t('what_is_their_relation_to_you') : t('what_is_relation_to_patient')}</div>
              <div className="">
                <Form.Control
                  as="textarea"
                  className="trans-background no-resize color-white font-14 custom-field h-100p"
                  value={otherRelation}
                  placeholder={t('enter_answer')}
                  onChange={(e) => {
                    if (e.target.value.length < 50) {
                      setOtherRelation(e.target.value);
                    }
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>

      <button className="button-ecds-next br py-2" onClick={handleNext}>
        <span>{t('next')}</span>
      </button>
    </div>
  );
}
