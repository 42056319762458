import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URI,
});

export const signup = async (data) => {
  try {
    let response = await api.post("auth/signup", data);
    console.log({ signupRes: response.data });
    return response?.data;
  } catch (error) {
    console.log("ERROR", error);
    return error?.response?.data;
  }
};
