import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function InstructionsComponent() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNext = async () => {
    navigate("/language");
  };

  return (
    <div className="ecds-container">
      <div className="content-div-5">
        <div className="text-center">
          <img
            src={require("../../Assets/Images/logo.png")}
            className="logo-smaller"
            alt="logo"
          />
        </div>
        <div className="question text-center">
          Intented use & Instructions for use
        </div>
        <p className="commitment_detail h-custom">
          The primary intended use of Opto Health is to assist healthcare
          professionals in the triage of patients entering Emergency Departments
          and UTCs. The system employs advanced algorithms and clinical decision
          support to:
          <br />
          <br />
          Assess patient symptoms
          <br />
          <br />
          Assign a priority score based on the severity of the condition
          <br />
          <br />
          Provide information on alternative services for patient management
          <br />
          <br />
          Life threatening and limb critical questions have been incorporated
          into the clinical question set and will highlight immediately into the
          clinical system if a patient has arrived and used Opto who meets the
          highest critical prioritisation. Opto Health is intended for use by
          trained healthcare professionals, including but not limited to:
          <br />
          <br />
          Emergency physicians
          <br />
          Nurses
          <br />
          <br />
          Receptionists/Administrators
          <br />
          <br />
          Paramedics
        </p>
      </div>
      <button className="button-ecds-next br py-2" onClick={handleNext}>
        <span>{t("next")}</span>
      </button>
    </div>
  );
}
