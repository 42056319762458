import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { basicDetail, booking, version } from "../../redux/slices/basicDetialSlice";
import { timer } from "../../redux/slices/timerSlice";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';

export default function PreWelcomeComponent() {
  const [answer1, setAnswer1] = useState();
  const [answer2, setAnswer2] = useState();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  var User = useSelector((state) => state.userData.value);
  var MyTimer = useSelector((state) => state.timer.value);

  useEffect(() => {
    dispatch(timer(null));
  }, []);

  const handleNext = async () => {
    if (!answer1) {
      return toast.error(`${t('please_select_an_option')}`);
    }
    if (answer1 === "else" && !answer2) {
      return toast.error(`${t('please_select_an_option')}`);
    }
    if (!answer2) {
      const bookingObj = {
        bookingFor: `${t('bookingForMySelf')}`,
        isBookingForChild: false,
      };
      dispatch(version({ version: "firstPerson" }))
      dispatch(booking({ booking: bookingObj }));
      navigate("/basic-details");
    } else if (answer2 === `${t('yes')}`) {
      const bookingObj = {
        bookingFor: `${t('bookingForSomeOneElse')}`,
        isBookingForChild: true,
      };
      dispatch(version({ version: "paediatric" }))
      dispatch(booking({ booking: bookingObj }));
      navigate("/basic-details");
      // navigate("/child-relationship");
    } else if (answer2 === `${t('no')}`) {
      const bookingObj = {
        bookingFor: `${t('bookingForSomeOneElse')}`,
        isBookingForChild: false,
      };
      dispatch(version({ version: "proxy" }))
      dispatch(booking({ booking: bookingObj }));
      navigate("/basic-details");
      // navigate("/person-relationship");
    }
  };

  const checkDob = () => {
    let y = User.user.dob.substring(0, 4);
    let m = User.user.dob.substring(4, 6);
    let d = User.user.dob.substring(6, 8);
    let dob = new Date(`${m}-${d}-${y}`);

    let month_diff = Date.now() - dob.getTime();
    let age_dt = new Date(month_diff);
    let year = age_dt.getUTCFullYear();
    let age = Math.abs(year - 1970);

    if (age >= 15) return true;
    else return false;
  };

  const startTimer = () => {
    if (!MyTimer) {
      dispatch(timer(new Date()));
    }
  };

  const handlePrevious = () => {
    navigate("/")
  }

  return (
    <div className="ecds-container">
      <div className="content-div">
        <img
          src={require("../../Assets/Images/logo.png")}
          className="logo-smaller"
          alt="logo"
        />
        <div className="arrow-left">
          <img
            className="arrow-left-img"
            src={require("../../Assets/Images/arrow-left.png")}
            alt="arrow"
            onClick={handlePrevious}
          />
        </div>

        <div className="question">{t('bookingFor')}</div>
        <div>
          {checkDob && (
            <div
              onClick={() => {
                setAnswer1("me");
                startTimer();
                setAnswer2()
              }}
              className={
                answer1 === "me"
                  ? "active-option answer-div"
                  : "inactive-option answer-div"
              }
            >
              {t('bookingForMySelf')}
            </div>
          )}

          <div
            onClick={() => {
              setAnswer1("else");
              startTimer();
            }}
            className={
              answer1 === "else"
                ? "active-option answer-div"
                : "inactive-option answer-div"
            }
          >
            {t('bookingForSomeOneElse')}
          </div>
        </div>
        {answer1 === "else" && (
          <>
            <div className="question">{t('IsthisForChild')}</div>
            <div>
              <div
                onClick={() => {
                  setAnswer2(`${t('yes')}`);
                  startTimer();
                }}
                className={
                  answer2 === `${t('yes')}`
                    ? "active-option answer-div"
                    : "inactive-option answer-div"
                }
              >
                {t('yes')}
              </div>
              <div
                onClick={() => {
                  setAnswer2(`${t('no')}`);
                  startTimer();
                }}
                className={
                  answer2 === `${t('no')}`
                    ? "active-option answer-div"
                    : "inactive-option answer-div"
                }
              >
                {t('no')}
              </div>
            </div>
          </>
        )}
      </div>

      <button className="button-ecds-next br py-2" onClick={handleNext}>
        <span>{t('next')}</span>
      </button>
    </div>
  );
}
