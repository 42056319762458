import React, { useEffect, useState } from "react";
import { FormControl, Input, InputAdornment } from "@mui/material";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { loading } from "../../redux/slices/loadingSlice";
import loader from "../../Assets/Images/loader.gif";
import Popup from "../AbdominalPain/Popup";
import { useTranslation } from "react-i18next";
import {
  getCurrentQuesContent,
  getSelectedOptionName,
} from "../../HelperFunctions/helper";
import { ecds } from "../../redux/slices/ecdsSlice";
import { updateEcdsPathwayUserdata } from "../../APIs/ecds";
import DatePickerUi from "../DatePickerUi";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { clinicalPathway } from "../../APIs/earProblem";
import { pathwayQuestions } from "../../redux/slices/pathwayQuestionsSlice";

export default function PostEcdsComponent() {
  const [answer1, setAnswer1] = useState();
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const questions = useSelector((state) => state.ecds.questions);
  const record = useSelector((state) => state.ecds.value);
  const { version } = useSelector((state) => state.basicDetail);
  const REASON_QUES_NO = version === "proxy" ? 30 : 21;
  const [currentQues, setCurrentQues] = useState(
    questions.find((q) => q.questionNumber === REASON_QUES_NO)
  );
  const [multiOptions, setMultiOptions] = useState([]);
  const [currentSelectedOption, setCurrentSelectedOption] = useState(
    currentQues.options.find((o) => o.nextQuestionNumber !== -1)
  );
  const [currentSelectedOptionForDate, setCurrentSelectedOptionForDate] =
    useState(null);
  const [currentSelectedOptionForTime, setCurrentSelectedOptionForTime] =
    useState(null);
  const [showDatePickerUi, setShowDatePickerUi] = useState(false);
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [date, setDate] = useState("");
  const [time, setTime] = useState(null);
  const [isToday, setIsToday] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [popup, setPopup] = useState(false);
  const [search, setSearch] = useState("");
  const usingLanguage = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  const { state } = useLocation();

  useEffect(() => {
    if (date && currentSelectedOptionForDate)
      setCurrentSelectedOptionForDate((p) => ({ ...p, option: date }));
    if (time && currentSelectedOptionForTime)
      setCurrentSelectedOptionForTime((p) => ({
        ...p,
        option: time.$d.toLocaleTimeString(),
      }));
  }, [date, time]);

  const filtering = () => {
    return currentQues.options?.filter((e) =>
      e.option?.toLowerCase().includes(search.toLowerCase())
    );
  };

  const handleNext = async () => {
    if (currentQues.questionNumber === REASON_QUES_NO)
      return handleTimeDateQuesNext();
    const q_content = getCurrentQuesContent(currentQues, "en");
    if (currentQues?.questionType === "input" && !inputValue)
      return toast.error(`${t("submit_answer")}`);
    if (
      currentQues?.questionType !== "input" &&
      !multiOptions.length &&
      !currentSelectedOption
    )
      return toast.error(`${t("select_option")}`);
    if (
      currentQues?.questionNumber === REASON_QUES_NO &&
      currentSelectedOption?.nextQuestionNumber !== -1
    ) {
      let msg = "";
      if (
        !currentSelectedOptionForDate ||
        currentSelectedOptionForDate?.option === "Select date"
      )
        msg = t("select_date_of_injury");
      else if (!currentSelectedOptionForTime) msg = t("select_time_of_injury");
      if (msg) return toast.error(msg);
    }

    let obj, nextQNo;
    if (currentQues?.questionType === "multiOption" && multiOptions.length) {
      obj = {
        nextQuestionNumber: multiOptions[0]?.nextQuestionNumber,
        questionDetails: {
          id: currentQues._id,
          content: q_content,
          questionType: currentQues.questionType,
        },
        answers: multiOptions.map((option) => ({
          id: option._id,
          code: option.code,
          content: option.option,
        })),
      };
      nextQNo = multiOptions[0]?.nextQuestionNumber;
      setMultiOptions([]);
    } else if (
      currentQues?.questionType === "singleOption" &&
      currentSelectedOption
    ) {
      obj = {
        nextQuestionNumber: currentSelectedOption?.nextQuestionNumber,
        questionDetails: {
          id: currentQues._id,
          content: q_content,
          questionType: currentQues.questionType,
        },
        answers: [
          {
            id: currentSelectedOption._id,
            code: currentSelectedOption.code || "P0",
            content: getSelectedOptionName(currentSelectedOption, "en"),
          },
        ],
      };
      nextQNo = currentSelectedOption?.nextQuestionNumber;
    } else if (currentQues.questionType === "input" && inputValue) {
      obj = {
        nextQuestionNumber: currentQues.nextQuestionNumber,
        questionDetails: {
          id: currentQues._id,
          content: q_content,
          questionType: currentQues.questionType,
        },
        answers: [
          {
            content: inputValue,
            code: "P0",
          },
        ],
      };
    }

    const idx = questions.findIndex((q) => q._id === currentQues._id);
    const rec = [...record];
    rec[idx] = obj;
    dispatch(ecds(rec));
    if (nextQNo && nextQNo !== -1)
      setCurrentQues(questions.find((q) => q.questionNumber === nextQNo));
    const loadingValue = parseInt(((idx / questions.length) * 100) / 2);
    dispatch(loading({ percent: loadingValue }));

    console.log("obj => ", obj);

    if (
      (currentQues.questionType === "input" &&
        currentQues?.nextQuestionNumber === -1) ||
      (currentQues?.questionType === "multiOption" &&
        multiOptions[0]?.nextQuestionNumber === -1) ||
      currentSelectedOption?.nextQuestionNumber === -1
    ) {
      const reason_ques_id = questions.find(
        (q) => q.questionNumber === REASON_QUES_NO
      )._id;
      const sliceIdx =
        rec.findIndex((r) => r?.questionDetails?.id === reason_ques_id) + 1;
      const payload = {
        record: rec.slice(sliceIdx).filter((r) => r),
      };
      console.log("payload => ", payload);
      setShowLoader(true);
      const response = await updateEcdsPathwayUserdata(payload);
      console.log("ecds update response=> ", response);
      const pathway = await clinicalPathway({
        diseaseID: state?.pathway,
        version: version,
      });
      dispatch(pathwayQuestions(pathway));
      if (response && pathway) {
        navigate("/pathway", { state: { showBadPainImages: true } });
      } else {
        toast.error(response?.message);
        setShowLoader(false);
      }
    }
    setCurrentSelectedOption(null);
  };
  const handleTimeDateQuesNext = async () => {
    if (
      !currentSelectedOptionForDate ||
      currentSelectedOptionForDate?.option === "Select date"
    )
      return toast.error(t("select_date_of_injury"));
    if (!currentSelectedOptionForTime)
      return toast.error(t("select_time_of_injury"));
    let nextQNo;
    const idx = questions.findIndex((q) => q._id === currentQues._id);
    const rec = [...record];
    const dateQ = questions.find(
      (q) => q.questionNumber === currentSelectedOption.nextQuestionNumber
    );
    const timeQ = questions.find(
      (q) => q.questionNumber === dateQ.options[0].nextQuestionNumber
    );

    const dateObj = {
      nextQuestionNumber: currentSelectedOptionForDate?.nextQuestionNumber,
      questionDetails: {
        id: dateQ._id,
        content: getCurrentQuesContent(dateQ, "en"),
        questionType: dateQ.questionType,
      },
      answers: [
        {
          id: currentSelectedOptionForDate._id,
          code: currentSelectedOptionForDate.code || "P0",
          content: getSelectedOptionName(currentSelectedOptionForDate, "en"),
        },
      ],
    };
    const timeObj = {
      nextQuestionNumber: currentSelectedOptionForTime?.nextQuestionNumber,
      questionDetails: {
        id: timeQ._id,
        content: getCurrentQuesContent(timeQ, "en"),
        questionType: timeQ.questionType,
      },
      answers: [
        {
          id: currentSelectedOptionForTime._id,
          code: currentSelectedOptionForTime.code || "P0",
          content: getSelectedOptionName(currentSelectedOptionForTime, "en"),
        },
      ],
    };
    nextQNo = currentSelectedOptionForTime?.nextQuestionNumber;
    rec[idx] = {
      ...rec[idx],
      nextQuestionNumber: currentSelectedOption?.nextQuestionNumber,
    };
    rec[idx + 1] = dateObj;
    rec[idx + 2] = timeObj;
    dispatch(ecds(rec));
    if (nextQNo && nextQNo !== -1)
      setCurrentQues(questions.find((q) => q.questionNumber === nextQNo));
    const loadingValue = parseInt(((idx / questions.length) * 100) / 2);
    dispatch(loading({ percent: loadingValue }));
    console.log("date obj => ", dateObj);
    console.log("time obj => ", timeObj);
    setCurrentSelectedOption(null);
  };

  const handlePrevious = () => {
    let idx = questions.findIndex((q) => q._id === currentQues._id);
    if (currentQues.questionNumber === REASON_QUES_NO)
      return navigate("/triage");
    while (!record[idx - 1]) idx = idx - 1;
    let ques = questions.find(
      (q) => q._id === record[idx - 1].questionDetails.id
    );
    if ([23].includes(ques.questionNumber)) {
      ques = questions.find((q) => q.questionNumber === REASON_QUES_NO);
      setCurrentSelectedOptionForDate(null);
      setCurrentSelectedOptionForTime(null);
      setTime(null);
      setShowDatePickerUi(false);
      idx = idx - 2;
    }
    setCurrentQues(ques);
    const loadingValue = parseInt((((idx - 2) / questions.length) * 100) / 2);
    dispatch(loading({ percent: loadingValue }));
    if (ques.questionType === "singleOption") {
      const op = ques.options.find(
        (o) => o._id === record[idx - 1]?.answers[0]?.id
      );
      setCurrentSelectedOption({ ...op, nextQuestionNumber: 22 });
    }
  };

  const handleMultiOptions = (o) => {
    setAnswer1(getSelectedOptionNameWithLang(o));
    if (!multiOptions.includes(o)) {
      setMultiOptions(multiOptions.concat(o));
    } else {
      const filtered = multiOptions.filter(
        (m) => m.option !== getSelectedOptionNameWithLang(o)
      );
      setMultiOptions(filtered);
    }
  };

  var getSelectedOptionNameWithLang = (o) => {
    return getSelectedOptionName(o, usingLanguage);
  };

  const renderTimeDateQuestions = () => {
    const dateQ = questions.find(
      (q) => q.questionNumber === currentSelectedOption.nextQuestionNumber
    );
    const timeQ = questions.find(
      (q) => q.questionNumber === dateQ.options[0].nextQuestionNumber
    );

    return (
      <>
        <div className="question">{getCurrentQuesContent(dateQ)}</div>
        <div>
          {dateQ.options?.map((o, i) => {
            return (
              <div
                key={i}
                onClick={() => {
                  if (showDatePickerUi) {
                    setDate("");
                    setShowDatePickerUi(false);
                  }
                  const op = { ...o, multiLanguageOption: null };
                  if (i === 0) {
                    setIsToday(true);
                    op.option = new Date().toLocaleDateString();
                  } else if (i === 1) {
                    if (isToday && !time) setCurrentSelectedOptionForTime(null);
                    setIsToday(false);
                    op.option = new Date(
                      new Date().setDate(new Date().getDate() - 1)
                    ).toLocaleDateString();
                  } else if (i === 2) {
                    if (isToday && !time) setCurrentSelectedOptionForTime(null);
                    setIsToday(false);
                    setCurrentSelectedOptionForDate(null);
                    setDate("");
                    setShowDatePickerUi(true);
                  }
                  setCurrentSelectedOptionForDate(op);
                }}
                className={
                  o._id === currentSelectedOptionForDate?._id
                    ? "active-option answer-div"
                    : "inactive-option answer-div"
                }
              >
                {getSelectedOptionNameWithLang(o)}
              </div>
            );
          })}
          {showDatePickerUi && (
            <div className="calender-wrapper  calender-wrapper2">
              <DatePickerUi setDob={setDate} />
            </div>
          )}
        </div>
        <div className="question">{getCurrentQuesContent(timeQ)}</div>
        <div>
          {currentSelectedOptionForDate && isToday && (
            <div
              onClick={() => {
                setTime(null);
                const t = new Date(
                  new Date().setHours(new Date().getHours() - 1)
                ).toLocaleTimeString();
                const op = {
                  ...timeQ.options[0],
                  multiLanguageOption: null,
                  option: t,
                };
                setCurrentSelectedOptionForTime(op);
              }}
              className={
                timeQ.options[0]._id === currentSelectedOptionForTime?._id
                  ? "active-option answer-div"
                  : "inactive-option answer-div"
              }
            >
              {getSelectedOptionNameWithLang(timeQ.options[0])}
            </div>
          )}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              open={showTimePicker}
              value={time}
              onChange={(v) => setTime(v)}
              onClose={() => {
                if (!time) setCurrentSelectedOptionForTime(null);
                setShowTimePicker(false);
              }}
              renderInput={({ inputRef }) => (
                <Form.Control
                  style={{ borderWidth: 0 }}
                  className={`trans-background border no-shadow cursor-pointer color-white font-14 ${
                    time ? "c-blue" : ""
                  }`}
                  readOnly
                  ref={inputRef}
                  value={time ? time.$d?.toLocaleTimeString() : ""}
                  onClick={() => {
                    setShowTimePicker(true);
                    const op = {
                      ...timeQ.options[1],
                      multiLanguageOption: null,
                    };
                    setCurrentSelectedOptionForTime(op);
                  }}
                  placeholder={getSelectedOptionNameWithLang(timeQ.options[1])}
                />
              )}
            />
          </LocalizationProvider>
        </div>
      </>
    );
  };

  return (
    <div className="ecds-container">
      <div className="content-div">
        <Popup
          show={popup}
          setShow={setPopup}
          message={
            getCurrentQuesContent(currentQues, usingLanguage) ||
            currentQues?.info
          }
        />

        <div className="arrow-left">
          <img
            className="arrow-left-img"
            src={require("../../Assets/Images/arrow-left.png")}
            alt="arrow"
            onClick={handlePrevious}
          />
        </div>
        <img
          src={require("../../Assets/Images/logo.png")}
          className="logo-smaller"
          alt="logo"
        />
        {showLoader && (
          <img className="loader_div" src={loader} alt="loading..." />
        )}
        {currentQues.questionNumber === REASON_QUES_NO ? (
          renderTimeDateQuestions()
        ) : (
          <>
            <div className="question">
              {getCurrentQuesContent(currentQues)}
              {currentQues?.isInfoButtonEnable && (
                <img
                  src={require("../../Assets/Images/info-icon.png")}
                  style={{ width: 18 }}
                  className="cursor-pointer"
                  onClick={() => setPopup(true)}
                  alt="info"
                />
              )}
            </div>
            {[].includes(currentQues.questionNumber) && (
              <FormControl className="answer-multiple">
                <Input
                  disableUnderline
                  onChange={(e) => setSearch(e.target.value)}
                  style={{ fontSize: 12, marginTop: 3 }}
                  sx={{ input: { color: "white" } }}
                  value={search}
                  placeholder={t("search")}
                  startAdornment={
                    <InputAdornment position="start">
                      <img
                        src={require("../../Assets/Images/searchIcon.png")}
                        className="logo-smallest"
                        alt="search"
                      />
                    </InputAdornment>
                  }
                />
              </FormControl>
            )}
            {currentQues?.questionType === "input" && (
              <div className="">
                <Form.Control
                  value={inputValue}
                  onChange={(e) => {
                    if (e.target.value.length < 100) {
                      setInputValue(e.target.value);
                    }
                  }}
                  rows={3}
                  as="textarea"
                  placeholder={t("enter_answer")}
                  className="trans-background no-resize color-white font-14 custom-field "
                />
              </div>
            )}

            {currentQues?.questionType === "singleOption" && (
              <div
                className={
                  currentQues.questionNumber !== REASON_QUES_NO
                    ? "h-100 overflow-auto"
                    : ""
                }
              >
                {(search ? filtering() : currentQues.options)?.map((o, i) => {
                  return (
                    <div
                      key={i}
                      onClick={() => {
                        setAnswer1(getSelectedOptionNameWithLang(o));
                        setCurrentSelectedOption(o);
                      }}
                      className={
                        o._id === currentSelectedOption?._id
                          ? "active-option answer-div"
                          : "inactive-option answer-div"
                      }
                    >
                      {getSelectedOptionNameWithLang(o)}
                    </div>
                  );
                })}
              </div>
            )}

            {currentQues?.questionType === "multiOption" && (
              <div>
                {currentQues?.options.map((o, i) => {
                  return (
                    <div
                      key={i}
                      onClick={() => handleMultiOptions(o)}
                      className={
                        multiOptions.includes(o)
                          ? "active-option answer-div"
                          : "inactive-option answer-div"
                      }
                    >
                      {o.option}
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
      </div>

      <button className="button-ecds-next br py-2" onClick={handleNext}>
        <span>{t("Next")}</span>
      </button>
    </div>
  );
}
