import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import WelcomeScreenIcons from "../../Assets/Images/welcome-screen";

export default function WelcomeComponent() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="d-flex align-items-center">
      <div className="text-center">
        <img
          src={require("../../Assets/Images/opto-logo.png")}
          className="w-75"
          alt="logo"
        />
        <p className="heading mb-0">Welcome</p>
        <p className="welcome-info">Check-in & Triage here</p>
        <button
          className="get-started-btn br py-2 w-50 mt-2 mb-5"
          onClick={() => navigate("/language")}
        >
          <span>{t("Get Started")}</span>
        </button>
        <p
          onClick={() => navigate("/commitment")}
          className="instructions_para mt-5"
        >
          {t("our_data_commitment_to_you")}
        </p>
        <p
          onClick={() => navigate("/instructions")}
          className="instructions_para"
        >
          Intented use & Instructions for use
        </p>
        <div className="d-flex align-items-center justify-content-center gap-4 mt-2">
          {WelcomeScreenIcons.map((Icon, i) => (
            <img key={i} src={Icon} alt="bottom-icon" width={48} />
          ))}
        </div>
      </div>
    </div>
  );
}
