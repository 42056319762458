import React from "react";
import { useMediaQuery } from "react-responsive";
import ProgressSlider from "../../Components/Shared/ProgressSlider";
import EarProblemComponent from "../../Components/EarProblem/EarProblemComponent";

export default function EarProblem() {
  const isMobile = useMediaQuery({
    query: "(max-width: 820px)",
  });

  return (
    <div className="custom-container">
      {isMobile ? (
        <div className="mobile-box-pe">
          <div className="slider">
            <ProgressSlider />
          </div>
          <EarProblemComponent />
        </div>
      ) : (
        <div className="backdrop">
          <div className="pc-box-pe position-relative">
            <div className="slider">
              <ProgressSlider />
            </div>
            <EarProblemComponent />
          </div>
        </div>
      )}
    </div>
  );
}
