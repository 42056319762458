import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URI,
});

export const postEarProblem = async (record, timer) => {
  console.log({ record, timer });
  try {
    let response = await api.post(
      `${process.env.REACT_APP_BASE_URI}clinicalPath/addClinicalPathUserData`,
      { record, timer, problem_type: "Ear Problem" },
      {
        headers: {
          Authorization: localStorage.getItem("opto_token"),
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    console.log("ERROR", error);
    return error?.response?.data;
  }
};

export const postAbdominalPain = async (data) => {
  console.log("=======", data);
  try {
    let response = await api.post(
      `${process.env.REACT_APP_BASE_URI}clinicalPath/addClinicalPathUserData`,
      data,
      {
        headers: {
          Authorization: localStorage.getItem("opto_token"),
        },
      }
    );
    return response?.data;
  } catch (error) {
    console.log("ERROR", error);
    return error?.response?.data;
  }
};

export const getFilteredClinicalProblems = async (data) => {
  console.log("......");
  try {
    let response = await api.post(
      `${process.env.REACT_APP_BASE_URI}clinicalPath/filterDiseases `,
      data,
      {
        headers: {
          Authorization: localStorage.getItem("opto_token"),
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    console.log("ERROR", error);
    return error?.response?.data;
  }
};

export const clinicalPathway = async (data) => {
  console.log("clinicalProblems......");
  try {
    let response = await api.post(
      `${process.env.REACT_APP_BASE_URI}clinicalPath/getQuestionByDiseaseID`,
      data,
      {
        headers: {
          Authorization: localStorage.getItem("opto_token"),
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    console.log("ERROR", error);
    return error?.response?.data;
  }
};
