import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { publicHealthData } from "../../redux/slices/ecdsSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PublicHealthQuestionsComponent = () => {
  const { publicHealthQuestions } = useSelector((state) => state.ecds);
  const [answers, setAnswers] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNext = () => {
    if (answers.length < publicHealthQuestions.length)
      return toast.error("Please select all the answers below");
    dispatch(publicHealthData(answers));
    navigate("/pre-ecds-questions");
  };

  const handlePrevious = () => {
    navigate("/pre-ecds");
  };

  return (
    <div className="ecds-container">
      <div className="arrow-left">
        <img
          className="arrow-left-img"
          src={require("../../Assets/Images/arrow-left.png")}
          alt="arrow"
          onClick={handlePrevious}
        />
      </div>
      <div className="d-flex check_question_wrap">
        <img
          src={require("../../Assets/Images/logo.png")}
          className="logo-smaller"
          alt="logo"
        />
        <h2>{t("please_select_those_that_apply")}</h2>
        {publicHealthQuestions.map((q, idx) => {
          return (
            <div className="check_question d-flex" key={idx}>
              <span>{q.Question}</span>
              {q.Option.map((o, i) => {
                return (
                  <label className="check_box" key={i}>
                    <input
                      type="radio"
                      onChange={() =>
                        setAnswers((p) => {
                          const rec = [...p];
                          rec[idx] = {
                            question: q.Question,
                            option: o === "Yes" ? "true" : "false",
                          };
                          return rec;
                        })
                      }
                      checked={
                        answers[idx]?.option ===
                        (o === "Yes" ? "true" : "false")
                      }
                    />
                    <span>{o}</span>
                  </label>
                );
              })}
            </div>
          );
        })}
      </div>
      <button className="button-ecds-next br py-2" onClick={() => handleNext()}>
        <span>{t("next")}</span>
      </button>
    </div>
  );
};
export default PublicHealthQuestionsComponent;
