import React from "react";
import { useMediaQuery } from "react-responsive";
import InstructionsComponent from "../../Components/Instructions/InstructionsComponent";

export default function Instructions() {
    const isMobile = useMediaQuery({
        query: "(max-width: 820px)",
    });

    return (
        <div className="custom-container">
            {isMobile ? (
                <div className="mobile-box-pe">
                    <InstructionsComponent />
                </div>
            ) : (
                <div className="backdrop">
                    <div className="pc-box-pe position-relative">
                        <InstructionsComponent />
                    </div>
                </div>
            )}
        </div>
    );
}
