import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function ChildAgeComponent() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isLightMode = useSelector((state) => state.isLightMode?.value);
  const basicDetail = useSelector((state) => state.basicDetail?.booking);

  useEffect(() => {
    const id = setTimeout(handleNext, 30 * 1000);
    return () => clearTimeout(id);
  }, []);

  const handleNext = () => {
    navigate("/");
  };

  const errMsg =
    basicDetail?.bookingFor === t("bookingForMySelf")
      ? "Please check you have entered your date of birth correctly or if you are under 15 years old please go to reception"
      : basicDetail?.isBookingForChild
      ? `Please check that you have entered their date of birth correctly or if they are over 18 years old please select "No" when asked "Is this for a child?"`
      : `Please check that you have entered their date of birth correctly or if they are under 15 years old please select "Yes" when asked "Is this for a child?"`;

  return (
    <div className="ecds-container takeSeat-Container">
      <div className="takeSeat">
        <img
          src={
            isLightMode
              ? require("../../Assets/Images/posttriage.png")
              : require("../../Assets/Images/done.png")
          }
          className="logoCenter"
          alt="logo"
        />
        <p>{errMsg}</p>
      </div>
      <button className="button-post-triage-next br py-2" onClick={handleNext}>
        <span>{t("ok")}</span>
      </button>
    </div>
  );
}
