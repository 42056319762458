import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Welcome from "../Pages/Welcome/Welcome";
import BasicDetails from "../Pages/BasicDetails/BasicDetails";
import Ecds from "../Pages/Ecds/Ecds";
import PostEcds from "../Pages/Ecds/PostEcds";
import PreEcdsQuestions from "../Pages/Ecds/PreEcdsQuestions";
import PreEcds from "../Pages/Ecds/PreEcds";
import PublicHealthQuestions from "../Pages/PublicHealthQuestions";
import PostDetails from "../Pages/PostDetails/PostDetails";
import Triage from "../Pages/Triage/Triage";
import EarProblem from "../Pages/EarProblem/EarProblem";
import PostTriage from "../Pages/PostTriage/PostTriage";
import ChildAge from "../Pages/ChildAge/ChildAge";
import PreWelcome from "../Pages/PreWelcome/PreWelcome";
import Identifications from "../Pages/Identifications/Identifications";
import ChildRelationship from "../Pages/Relationship/ChildRelationship";
import PersonRelationship from "../Pages/Relationship/PersonRelationship";
import ChildDetail from "../Pages/Relationship/ChildDetail";
import PersonDetail from "../Pages/Relationship/PersonDetail";
import AbdominalPain from "../Pages/AbdominalPain/AbdominalPain";
import Main from "../Pages/PreWelcome/Main";
import AdminApproval from "../Pages/AdminApproval/AdminApproval";
import ClinicalPathway from "../Pages/ClinicalPathway/ClinicalPathway";
import PrePathway from "../Pages/ClinicalPathway/PrePathway";
import Commitment from "../Pages/Commitment/Commitment";
import Instructions from "../Pages/Instructions/Instructions";
import LanguageSelector from "../Pages/LanguageSelector/LanguageSelector";
import EmergencyContact from "../Pages/EmergencyContact/EmergencyContact";
import Accompanying from "../Pages/Accompanying/Accompanying";
import Accompany from "../Pages/Accompanying/Accompany";

export default function index() {
  return (
    <Router>
      <ToastContainer
        autoClose={2500}
        position={"top-right"}
        closeOnClick="true"
      />

      <Routes>
        <Route path="/" exact element={<Welcome />} />
        <Route path="/booking" exact element={<PreWelcome />} />
        <Route path="/basic-details" exact element={<BasicDetails />} />
        <Route path="/submit-basic-details" exact element={<PostDetails />} />
        <Route path="/pre-ecds" exact element={<PreEcds />} />
        <Route path="/pre-ecds-questions" exact element={<PreEcdsQuestions />} />
        <Route path="/public-health-questions" exact element={<PublicHealthQuestions />} />
        <Route path="/ecds" exact element={<Ecds />} />
        <Route path="/post-ecds" exact element={<PostEcds />} />
        <Route path="/triage" exact element={<Triage />} />
        <Route path="/ear-problem" exact element={<EarProblem />} />
        <Route path="/post-triage" exact element={<PostTriage />} />
        <Route path="/child-age" exact element={<ChildAge />} />
        <Route
          path="/identifications-detail"
          exact
          element={<Identifications />}
        />
        <Route path="/emergency-contact" exact element={<EmergencyContact />} />
        <Route
          path="/child-relationship"
          exact
          element={<ChildRelationship />}
        />
        <Route
          path="/person-relationship"
          exact
          element={<PersonRelationship />}
        />
        <Route path="/child-detail" exact element={<ChildDetail />} />
        <Route path="/person-detail" exact element={<PersonDetail />} />
        <Route path="/clinical-pathway" exact element={<AbdominalPain />} />
        <Route path="/hospital" exact element={<Main />} />
        <Route path="/admin-approval" exact element={<AdminApproval />} />
        <Route path="/pathway" exact element={<ClinicalPathway />} />
        <Route path="/pre-pathway" exact element={<PrePathway />} />
        <Route path="/commitment" exact element={<Commitment />} />
        <Route path="/instructions" exact element={<Instructions />} />
        <Route path="/language" exact element={<LanguageSelector />} />
        <Route path="/accompanying" exact element={<Accompanying />} />
        <Route path="/accompany" exact element={<Accompany />} />
      </Routes>
    </Router>
  );
}
