import React from "react";
import { useMediaQuery } from "react-responsive";
import PostTriageComponent from "../../Components/PostTriage/PostTriageComponent";
import AdminApprovalComponent from "../../Components/AdminApproval/AdminApprovalComponent";

export default function AdminApproval() {
  const isMobile = useMediaQuery({
    query: "(max-width: 820px)",
  });

  return (
    <div className="custom-container">
      {isMobile ? (
        <div className="mobile-box-pe ">
          <AdminApprovalComponent />
        </div>
      ) : (
        <div className="backdrop">
          <div className="pc-box-pe  position-relative">
            <AdminApprovalComponent />
          </div>
        </div>
      )}
    </div>
  );
}
