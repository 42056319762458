import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: null,
};

export const signupSlice = createSlice({
  name: "signupSlice",
  initialState,
  reducers: {
    signupData: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { signupData } = signupSlice.actions;

export default signupSlice.reducer;
