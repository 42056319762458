import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import WelcomeComponent from "../../Components/Welcome/WelcomeComponent";
import { useNavigate } from "react-router-dom";

export default function Welcome() {
  const [deviceId] = useState(() => localStorage.getItem("deviceId"));
  const navigate = useNavigate();
  const isMobile = useMediaQuery({
    query: "(max-width: 820px)",
  });

  useEffect(() => {
    if (!deviceId) navigate("/hospital");
  }, []);

  return (
    <div className="custom-container">
      {isMobile ? (
        <div className="mobile-box">
          <WelcomeComponent />
        </div>
      ) : (
        <div className="backdrop">
          <div className="pc-box position-relative">
            <WelcomeComponent />
          </div>
        </div>
      )}
    </div>
  );
}
