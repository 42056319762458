import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment";
import { postAbdominalPain } from "../../APIs/earProblem";
import { earProblem } from "../../redux/slices/earProblemSlice";
import { timer } from "../../redux/slices/timerSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toastify } from "../../HelperFunctions/toastify";
import { loading } from "../../redux/slices/loadingSlice";
import {
  ABDOMINAL_PAIN_QUESTIONS,
  ARM_INJURD_PATHWAY,
} from "../../Config/constants";
import {
  ALLERGY_CLINICAL_PATHWAY,
  ANXIETY_PATHWAY,
  HIP_PAIN_PATHWAY,
  HAND_INJURY_PATHWAY,
  ASSAULT_CLINICAL_PATHWAY,
  ANAL_PAIN_CLINICAL_PATHWAY,
} from "../../Config/constants";
import loader from "../../Assets/Images/loader.gif";
import Popup from "./Popup";

export default function AbdominalPainComponent() {
  const [answer1, setAnswer1] = useState();
  const [currentQues, setCurrentQues] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const problemType = useSelector((state) => state.problemType.value.problem);
  const questions =
    problemType === "Abdominal Pain"
      ? ABDOMINAL_PAIN_QUESTIONS
      : problemType === "I have been assaulted"
      ? ASSAULT_CLINICAL_PATHWAY
      : problemType === "Anal Pain"
      ? ANAL_PAIN_CLINICAL_PATHWAY
      : problemType === "Allergic Reaction"
      ? ALLERGY_CLINICAL_PATHWAY
      : problemType === "Arm injury"
      ? ARM_INJURD_PATHWAY
      : problemType === "Anxiety"
      ? ANXIETY_PATHWAY
      : problemType === "Hip Pain"
      ? HIP_PAIN_PATHWAY
      : problemType === "Hand Injury"
      ? HAND_INJURY_PATHWAY
      : [];
  const userData = useSelector((state) => state.userData.value);
  const [quesList, setQuesList] = useState([]);
  const [multiOptions, setMultiOptions] = useState([]);
  const [selectedQues, setSelectedQues] = useState([]);
  const [currentSelectedOption, setCurrentSelectedOption] = useState(null);
  const [multiSelected, setMultiSelected] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [popup, setPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  var MyTimer = useSelector((state) => state.timer.value);
  useEffect(() => {
    if (questions?.length) {
      setCurrentQues(questions[0]);
    }
  }, [questions]);

  useEffect(() => {
    dispatch(loading({ percent: 51 }));
  }, []);

  const formatDob = (date) => {
    if (date) {
      let y = date.substring(0, 4);
      let m = date.substring(4, 6);
      let d = date.substring(6, 8);
      const age = `${y}-${m}-${d}`;
      return moment().diff(age, "years");
    }
  };

  const handleNext = async () => {
    const gender =
      userData?.user.gender.charAt(0).toUpperCase() +
      userData?.user.gender.slice(1);
    const age = formatDob(userData?.user.dob);
    const { Condition, C_Type, C_N_Question, Question_No, Question } =
      currentQues;
    let _time = (new Date().getTime() - new Date(MyTimer).getTime()).toString();

    if (currentQues?.Type === "Free text" && inputValue === "") {
      return toast.error("Please submit your answer");
    }
    if (
      !multiOptions.length &&
      !currentSelectedOption &&
      currentQues?.Type === "Options"
    ) {
      return toast.error("Please select an option below");
    }
    if (
      !multiOptions.length &&
      !currentSelectedOption &&
      currentQues?.Type === "Multi Options"
    ) {
      return toast.error("Please select an option below");
    }

    if (multiOptions.length) {
      let code = [];
      let answer = [];
      selectedQues.filter((s) => s.question !== Question);
      for (const e of multiOptions) {
        code.push(e.code);
        answer.push(e.option);
      }
      const obj = {
        code: code.toString(),
        answer: answer.toString(),
        question: Question,
      };
      const questionIndex = selectedQues.findIndex(
        (q) => q.question === Question
      );
      questionIndex !== -1
        ? (selectedQues[questionIndex] = obj)
        : selectedQues.push(obj);
      setMultiOptions([]);
      setMultiSelected(!multiSelected);
    }
    if (currentSelectedOption) {
      const { code, option, N_Question } = currentSelectedOption;
      const obj =
        Question === "Does the pain move into your back?" && option === "Yes"
          ? {
              code: age < 35 ? "P3" : "P2",
              answer: option,
              question: Question,
            }
          : Question === "Is your arm injury after a fall?" && option === "Yes"
          ? {
              code: age < 65 ? "P4" : "P3",
              answer: option,
              question: Question,
            }
          : {
              code,
              answer: option,
              question: Question,
            };
      const questionIndex = selectedQues.findIndex(
        (q) => q.question === Question
      );
      questionIndex !== -1
        ? (selectedQues[questionIndex] = obj)
        : selectedQues.push(obj);
      setCurrentSelectedOption(null);
      setAnswer1("");
    }
    if (inputValue) {
      const obj = {
        code: "P0",
        answer: inputValue,
        question: Question,
      };
      const questionIndex = selectedQues.findIndex(
        (q) => q.question === Question
      );
      questionIndex !== -1
        ? (selectedQues[questionIndex] = obj)
        : selectedQues.push(obj);
      setInputValue("");
      // setNextInput(!nextInput);
    }
    if (
      currentQues?.N_Question === 0 ||
      currentSelectedOption?.N_Question === 0
    ) {
      const payload = {
        record: selectedQues,
        timer: _time,
        problem_type: problemType,
      };
      setShowLoader(true);
      handleProgressBar();
      const response = await postAbdominalPain(payload);
      console.log("abdominal", response);
      if (response) {
        setShowLoader(false);
        dispatch(earProblem(null));
        dispatch(timer(null));
        navigate("/post-triage");
      } else {
        toastify(response?.message, "error");
        setShowLoader(false);
      }
    }
    if (!Condition) {
      currentSelectedOption
        ? setCurrentQues(questions[currentSelectedOption?.N_Question])
        : setCurrentQues(questions[currentQues.N_Question]);
      setQuesList(quesList.concat(Question_No));
      handleProgressBar();
    } else if (Condition) {
      gender === C_Type
        ? setCurrentQues(questions[currentSelectedOption?.N_Question])
        : setCurrentQues(questions[currentQues.N_Question]);
      setQuesList(quesList.concat(Question_No));
      handleProgressBar();
    }
  };

  const handlePrevious = () => {
    if (currentQues.Question_No === 0) {
      navigate("/triage");
    } else {
      let a = quesList.pop();
      setCurrentQues(questions[a]);
      const current = questions[a];
      if (currentQues?.Question_No > 1) {
        const loadingValue1 =
          ((questions[a - 1].Question_No / questions?.length) * 100) / 2;
        const updatedValue1 = Math.round(loadingValue1 + 51);
        dispatch(loading({ percent: updatedValue1 }));
      }
      for (let s of selectedQues) {
        for (let c of current?.Option) {
          if (current.Question === s.question && c.option === s.answer) {
            setCurrentSelectedOption(c);
            setAnswer1(c.option);
          }
        }
      }
    }
  };

  const handleMultiOptions = (o) => {
    setAnswer1(o.option);
    if (!multiOptions.includes(o)) {
      setMultiOptions(multiOptions.concat(o));
    } else {
      const filtered = multiOptions.filter((m) => m.option !== o.option);
      setMultiOptions(filtered);
    }
  };

  const handleProgressBar = () => {
    const loadingValue =
      ((currentQues?.Question_No / questions?.length) * 100) / 2;
    const updatedValue = Math.round(loadingValue + 51);
    currentQues?.N_Question === 0
      ? dispatch(loading({ percent: 100 }))
      : dispatch(loading({ percent: updatedValue }));
  };

  const handleInfo = (ques) => {
    if (ques === "How severe is your pain?") {
      setPopupMessage("How bad is your pain?");
    }
    if (ques === "Have you vomited?") {
      setPopupMessage("Have you been sick / have you thrown up?");
    }
    if (ques == "Is your stool a different colour than normal?") {
      setPopupMessage("Is your poo a different colour than normal?");
    }
    if (ques === "Are you constipated?") {
      setPopupMessage("Are you not able to poo as normal?");
    }
    if (ques === "Are you having trouble passing urine?") {
      setPopupMessage("Are you having trouble doing a wee?");
    }
    if (ques === "Does your abdomen feel hard?") {
      setPopupMessage("Does your tummy feel hard?");
    }
    if (ques === "Is your abdomen swollen?") {
      setPopupMessage("Is your tummy swollen?");
    }
    if (ques === "Have you had recent abdominal surgery?") {
      setPopupMessage("Have you had recent tummy surgery?");
    }
    if (ques === "Have you had abdominal problems before?") {
      setPopupMessage("Have you had tummy problems before?");
    }
    if (ques === "Do you think you have haemorrhoids?") {
      setPopupMessage("Do you think you have piles?");
    }
    if (ques === "Do you have any injuries to your abdomen?") {
      setPopupMessage("Do you have any injuries to your tummy?");
    }
    if (ques === "What colour is your stool?") {
      setPopupMessage("What colour is your poo?");
    }
    if (ques === "Do you have a rash?") {
      setPopupMessage("Have you had a skin reaction?");
    }
    if (ques === "Do you have any abdominal pain?") {
      setPopupMessage("Do you have any tummy pain?");
    }
    if (ques === "Do you feel hot?") {
      setPopupMessage("Do you feel flushed?");
    }
    if (ques === "Have you collpased or felt faint today?") {
      setPopupMessage("Have you collpased or felt weak today?");
    }
    setPopup(true);
  };

  const handleInfoQues = () => {
    if (
      currentQues?.Question === "How severe is your pain?" ||
      currentQues?.Question === "Have you vomited?" ||
      currentQues?.Question ===
        "Is your stool a different colour than normal?" ||
      currentQues?.Question === "Are you constipated?" ||
      currentQues?.Question === "Are you having trouble passing urine?" ||
      currentQues?.Question === "Does your abdomen feel hard?" ||
      currentQues?.Question === "Is your abdomen swollen?" ||
      currentQues?.Question === "Have you had recent abdominal surgery?" ||
      currentQues?.Question === "Have you had abdominal problems before?" ||
      currentQues?.Question === "Do you think you have haemorrhoids?" ||
      currentQues?.Question === "Do you have any injuries to your abdomen?" ||
      currentQues?.Question === "What colour is your stool?" ||
      currentQues?.Question === "Do you have a rash?" ||
      currentQues?.Question === "Do you have any abdominal pain?" ||
      currentQues?.Question === "Do you feel hot?" ||
      currentQues?.Question === "Have you collpased or felt faint today?"
    ) {
      return true;
    }
  };

  return (
    <div className="ecds-container">
      <div className="content-div">
        <Popup show={popup} setShow={setPopup} message={popupMessage} />
        <div className="arrow-left">
          <img
            className="arrow-left-img"
            src={require("../../Assets/Images/arrow-left.png")}
            alt="arrow"
            onClick={handlePrevious}
          />
        </div>
        <img
          src={require("../../Assets/Images/logo.png")}
          className="logo-smaller"
          alt="logo"
        />
        {showLoader && (
          <img className="loader_div" src={loader} alt="loading..." />
        )}
        <div className="question">
          {currentQues?.Question}
          {handleInfoQues() && (
            <img
              src={require("../../Assets/Images/info-icon.png")}
              style={{ width: 18 }}
              className="cursor-pointer"
              onClick={() => handleInfo(currentQues?.Question)}
            />
          )}
        </div>
        {currentQues?.Type === "Free text" && (
          <>
            <div className="">
              <Form.Control
                value={inputValue}
                onChange={(e) => {
                  if (e.target.value.length < 100) {
                    setInputValue(e.target.value);
                  }
                }}
                rows={3}
                as="textarea"
                placeholder="Please enter your answer here"
                className="trans-background no-resize color-white font-14 custom-field "
              />
            </div>
          </>
        )}
        {currentQues?.Type === "Options" && (
          <div>
            {currentQues.Option.map((o) => {
              return (
                <div
                  onClick={() => {
                    setAnswer1(o.option);
                    setCurrentSelectedOption(o);
                  }}
                  className={
                    answer1 === o.option
                      ? "active-option answer-div"
                      : "inactive-option answer-div"
                  }
                >
                  {o.option}
                </div>
              );
            })}
          </div>
        )}
        {currentQues?.Type === "Multi Options" && (
          <div>
            {currentQues.Option.map((o) => {
              return (
                <div
                  onClick={() => handleMultiOptions(o)}
                  className={
                    multiOptions.includes(o)
                      ? "active-option answer-div"
                      : "inactive-option answer-div"
                  }
                >
                  {o.option}
                </div>
              );
            })}
          </div>
        )}
      </div>

      <button className="button-ecds-next br py-2" onClick={handleNext}>
        <span>Next</span>
      </button>
    </div>
  );
}
